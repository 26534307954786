<script lang="ts" setup></script>

<template>
   <LayoutCard :class="'no-jobs-card'">
      <p>{{ $t('my-week.no-jobs') }}</p>

      <NuxtLink class="no-planning-card__link" :to="'/profile'">
         <i class="fas fa-user"></i>
         {{ $t('my-week.manage-profile') }}
      </NuxtLink>
   </LayoutCard>
</template>

<style lang="scss" scoped>
.no-jobs-card {
   width: 100%;
   width: 100%;
   padding: var(--nxt-gutter);
   display: flex;
   flex-direction: column;
   text-align: start;
   gap: var(--nxt-gutter);

   p {
      margin: 0;
      color: var(--nxt-medium-grey);
   }

   button {
      margin-left: var(--nxt-gutter-small--negative);
   }
}

.no-planning-card__link {
   display: flex;
   align-items: center;
   gap: var(--nxt-gutter-small);
   margin-top: var(--nxt-gutter-small);
}
</style>
